<template>
  <section class="all-cases-view" v-loading="loading">
    <div class="container-small-table">
      <div class="listings-navbar">
      </div>

      <div class="primary-content-view-new">
        <div
          class="listings listings__table listings__all-cases"
          id="all-cases-listings"
        >
          <el-table
            :data="getAllLogs.data"
            height="calc(100vh - 200px)"
            @sort-change="onChangeSort"
            :default-sort="{
              prop: orderBy,
              order: orderType == 'asc' ? 'ascending' : 'descending',
            }"
          >
            <el-table-column label="Name" prop="reference" width="460">
              <template #default="scope">
                {{ scope.row.reference || "--" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="description"
              label="DESCRIPTION"
              min-width="360"
              sortable="custom"
            >
              <template #default="scope">
                <span class="error-break">{{ scope.row.description }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="USER"
              sortable="custom"
              prop="user"
              width="200"
            >
              <template #default="scope">
                {{
                  scope.row.user
                    ? scope.row.user.first_name + " " + scope.row.user.last_name
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="CREATED ON"
              sortable="custom"
              width="130"
              prop="created_at"
            >
              <template #default="scope">
                <div v-if="scope.row.created_at" class="d-flex">
                  <div class="date pr-10">
                    {{ momentWithTimezone(scope.row.created_at, "MM-DD-yyyy") }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="listings listings__pagination">
            <el-pagination
              v-model:currentPage="page"
              v-model:page-size="pageSize"
              :page-sizes="[25, 50, 75, 100]"
              layout="prev, pager, next, jumper, sizes, total"
              :total="getAllLogs.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import FilterHelper from "@/mixins/FilterHelper";
export default {
  name: "",
  components: {},
  mixins: [UserPermissionsHelper, FilterHelper],
  data() {
    return {
      page: 1,
      pageSize: 25,
      loading: false,
      orderBy: "",
      orderType: "",
      logsTable: {},
      orderLogs: {},
      searchCaseLoading: false,
      allOrders: [],
      searchOrderId: "",
      search_string: "",
    };
  },
  computed: {
    getStatusType() {
      return (statusVal) => {
        const status = statusVal.trim();
        if (status == "ACTIVE") {
          return "default";
        } else if (status == "COMPLETE") {
          return "success";
        } else {
          return "";
        }
      };
    },
    ...mapGetters("logs", ["getAllLogs"]),
    ...mapGetters("cases", ["getAllCases"]),
    ...mapGetters("orders", [
      "getAllOrders",
      "getCaseDeleteStatus",
      "getCaseDeleteError",
    ]),
  },
  async mounted() {
    this.setQueryParamsData();
    await this.fetchAllLogs();
    this.logsTable = JSON.parse(JSON.stringify(this.getAllLogs));
  },
  methods: {
    updateQueryParams(params) {
      let selectedParams = {
        selectedPage: params.page,
        selectedString: params.search_string,
        selectedPageSize: params.limit,
        selectedOrderBy: params.order_by,
        selectedOrderType: params.order_type,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
    async querySpuAttrNames(query) {
      if (query.length >= 3) {
        this.searchCaseLoading = true;
        let params = {
          search_string: query,
          get_all: true,
          status: caseConstants.ORDER_STATUSES,
        };
        await this.$store.dispatch("orders/getOrders", params);
        this.allOrders = this.getAllOrders.data;
        this.searchCaseLoading = false;
      }
    },
    getAuditlogsByOrder() {
      this.getAutoCompleteOrdersData();
    },
    async clearAuditLogsSearch() {
      this.searchOrderId = "";
      await this.fetchAllLogs(1);
    },
    async getAutoCompleteOrdersData() {
      // to eleminate empty search strings

      this.previousSearchString = this.search_string;

      this.page = 1;
      this.loading = true;
      let params = { page: this.page, type: "ORDER" };

      if (this.searchOrderId) {
        params.order_id = this.searchOrderId;
      }

      if(!this.isBillingUser){
          params.audit_section = "BILLING"
      }
      if(this.isBillingUser){
        params.audit_section = "TESTING"
      }

      await this.$store.dispatch("logs/fetchAllLogs", params);

      if (
        !this.search_string ||
        this.getAllLogs.search_string === this.search_string
      ) {
        this.logsTable = JSON.parse(JSON.stringify(this.getAllLogs));
      }
      this.loading = false;
    },
    setQueryParamsData() {
      this.page = this.$route.query.selectedPage
        ? parseInt(this.$route.query.selectedPage)
        : 1;
      this.pageSize = this.$route.query.selectedPageSize
        ? parseInt(this.$route.query.selectedPageSize)
        : 25;
      if (this.$route.query.selectedString) {
        this.search_string = this.$route.query.selectedString;
      }
      if (this.$route.query.selectedOrderBy) {
        this.orderBy = this.$route.query.selectedOrderBy;
      }
      if (this.$route.query.selectedOrderType) {
        this.orderType = this.$route.query.selectedOrderType;
      }
    },
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "";
        this.orderType = "";
      }
      this.fetchAllLogs();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchAllLogs();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchAllLogs();
    },
    prepareParams() {
      const params = {
        page: this.page,
        limit: this.pageSize,
        type: "RESULT_FILE",
      };
      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }

      if(!this.isBillingUser){
          params.audit_section = "BILLING"
      }
      if(this.isBillingUser){
        params.audit_section = "TESTING"
      }
      return params;
    },
    async fetchAllLogs() {
      try {
        this.loading = true;
        let params = this.prepareParams();
        await this.$store.dispatch("logs/fetchAllLogs", params);
        this.updateQueryParams(params);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    goToViewOrders() {
      this.$router.push({
        path: "/emr-orders/view",
      });
    },
  },
};
</script>

<style lang="scss">
.image-fluid {
  width: 100%;
}
</style>
